
import {
  Vue,
  Component,
  Prop,
  PropSync,
  Watch,
} from 'vue-property-decorator';
import { Property, Value } from '@/types/vjsf';
import {
  convertToDateTimeToLocaleString,
} from '@/helpers/converters/date';

// Lazy loading imports
const Tooltip = () => import(
  /* webpackChunkName: "form-tooltip" */
  /* webpackPrefetch: false */
  '@/components/Tooltip/Tooltip.vue'
);
const DatePicker = () => import(
  /* webpackChunkName: "form-date-picker" */
  /* webpackPrefetch: false */
  '@/components/formElements/elements/DatePicker.vue'
);
const TimePicker = () => import(
  /* webpackChunkName: "form-time-picker" */
  /* webpackPrefetch: false */
  '@/components/formElements/elements/TimePicker.vue'
);

@Component({
  name: 'DateTimeField',
  components: {
    TimePicker,
    DatePicker,
    Tooltip,
  },
})
export default class DateTimeField extends Vue {
  @PropSync('value', { required: true })
  private dateTime!: Value;

  @Prop({ required: true })
  private readonly property!: Property;

  @Prop({ required: true })
  private readonly locale!: string;

  @Prop({ required: true })
  private readonly required!: boolean;

  @Prop({ required: true })
  private readonly id!: string;

  private tab = 0;

  @Watch('dateTime')
  private switchTabs(value: string) {
    this.tab = value !== '' ? 1 : 0;
  }

  protected reset(): void {
    this.dateTime = null;
  }

  protected setToday(): void {
    const currentDate = new Date();

    const timezoneOffsetInMinutes = currentDate.getTimezoneOffset();

    currentDate.setMinutes(currentDate.getMinutes() - timezoneOffsetInMinutes);

    this.dateTime = `${currentDate.toISOString().slice(0, -8)}Z`;
  }

  // eslint-disable-next-line class-methods-use-this
  formatDate(dateInput: string|null): string {
    if (dateInput === '' || dateInput === null) {
      return '';
    }

    const options = {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };

    return convertToDateTimeToLocaleString(dateInput, this.locale, options);
  }
}

